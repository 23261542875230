import React from 'react'
import { Box, Text, Card } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const styles = {
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`],
  },
}

export default () => (
  <>
    <Seo title='Kebijakan Privasi' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='Kebijakan Privasi' />
        <Divider />
        <Box sx={styles.column}>
          <Card variant='paper'>
            <Text variant='p'>
              Saya tidak menjual atau membagikan informasi pribadi kepada pihak
              ketiga.
            </Text>
            <Text variant='p'>
              Penggunaan Cookie di website ini semata-mata hanyalah untuk
              melakukan analisis seberapa menarik konten saya, dari. negara mana
              saja pengunjungnya, dan siapa saja yang mengirimkan komentar.
            </Text>
            <Text variant='p'>
              Apabila Anda tidak setuju saya mengambil informasi Cookie Anda,
              silakan matikan lewat pengaturan Browser Anda.
            </Text>
            <Text variant='p'>
              Saya tidak bertanggung jawab atas diterbitkannya kembali konten
              yang ada di blog saya ke website lain atau media lain.
            </Text>
            <Text variant='p'>
              Kebijakan privasi ini dapat berubah sewaktu-waktu tanpa
              pemberitahuan sebelumnya.
            </Text>
          </Card>
        </Box>
      </Main>
    </Stack>
  </>
)
